// React & General Libraries
import React, { useState } from "react";
import "./spreadAndReturnsModal.css";
import axios from "axios";
import Plot from "react-plotly.js";
import { Button, Select, Modal } from "antd";

// PRODUCTS & RESOLUTION DATA
import res_values from "../../../data/res_values_days.json";
import products_data from "../../../data/products.json";

export default function SpreadAndReturnsModal({ onClose }) {
  const [product, setProduct] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [years, setYears] = useState(2);
  const [windowWidth, setWindowWidth] = useState(2);
  const [chartData, setChartData] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState(null);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  // Get session storage values
  const id_token = sessionStorage.getItem("id_token");
  const username = sessionStorage.getItem("username");

  // Function to fetch spread and returns chart data
  const fetchChartData = async () => {
    if (!product || !resolution || years < 2 || years > 20 || windowWidth < 2 || windowWidth > 30) {
      setError("Por favor, completa todos los campos antes de calcular.");
      setIsErrorModalVisible(true);
      return;
    }

    setIsCalculating(true);

    const requestData = {
      product: product,
      resolution: resolution,
      years: years,
      window: windowWidth,
    };

    try {
      const res = await axios.post("/api/spread_returns/", requestData, {
        headers: {
          Authorization: `Bearer ${id_token}`,
          Username: username,
        },
      });

      setChartData(JSON.parse(res.data));
    } catch (error) {
      setError("Hubo un error al calcular la correlación. Inténtalo de nuevo.");
      setIsErrorModalVisible(true);
    } finally {
      setIsCalculating(false);
    }
  };

  // Generate dropdown options for Years (2 to 20) and Window Width (2 to 30)
  const generateOptions = (min, max) =>
    Array.from({ length: max - min + 1 }, (_, i) => ({
      value: min + i,
      label: `${min + i}`,
    }));

  return (
    <div className="spread-modal-container">
      {/* Modal Close Button (Top Right) */}
      <button className="close-btn" onClick={onClose}>&times;</button>

      {/* Two-Column Layout for Inputs */}
      <div className="input-container">
        {/* Product Selection */}
        <div>
          <label className="form-label">Producto</label>
          <Select
            showSearch
            placeholder="Seleccione un producto"
            style={{ width: "100%" }}
            value={product}
            onChange={(value) => setProduct(value)}
          >
            {products_data.products.map((prod) => (
              <Select.Option key={prod.value} value={prod.value}>
                {prod.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        {/* Resolution Selection */}
        <div>
          <label className="form-label">Resolución en días</label>
          <Select
            showSearch
            placeholder="Seleccione resolución"
            style={{ width: "100%" }}
            value={resolution}
            onChange={(value) => setResolution(value)}
          >
            {res_values.map((res) => (
              <Select.Option key={res.value} value={res.value}>
                {res.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        {/* Years Selection */}
        <div>
          <label className="form-label">Años</label>
          <Select
            placeholder="Seleccione años"
            style={{ width: "100%" }}
            value={years}
            onChange={(value) => setYears(value)}
            options={generateOptions(2, 10)}
          />
        </div>

        {/* Window Width Selection */}
        <div>
          <label className="form-label">Tamaño de la Ventana</label>
          <Select
            placeholder="Seleccione tamaño"
            style={{ width: "100%" }}
            value={windowWidth}
            onChange={(value) => setWindowWidth(value)}
            options={generateOptions(2, 30)}
          />
        </div>
      </div>

      {/* Fetch Data Button */}
      <div className="text-center mt-4">
        <Button
          style={{ backgroundColor: "#050227", color: "white", borderRadius: "50px", fontSize: "16px" }}
          onClick={fetchChartData}
          disabled={isCalculating}
        >
          {isCalculating ? (
            <span className="loader"></span>
          ) : (
            "Obtener Gráfico"
          )}
        </Button>
      </div>

      {/* Display Chart with Scrollable Area */}
      {chartData && (
        <div className="spread-chart-container">
          {/* Chart Close Button (Top Left) */}
          <button className="chart-close-btn" onClick={() => setChartData(null)}>&times;</button>

          <h4 className="chart-title">Gráfico de Spread y Rendimientos</h4>
          <div className="spread-chart-box">
            <Plot
              data={chartData.data}
              layout={{
                ...chartData.layout,
                width: 1600,  
                height: 5000, 
                grid: {
                  rows: 7,       
                  columns: 1,    
                  pattern: "independent",
                },
                showlegend: true,
                margin: { l: 80, r: 10, t: 80, b: 80 }, 
                yaxis: {
                  title: "Value",
                  automargin: true,
                  tickfont: { size: 14 },
                },
                xaxis: {
                  title: "Time",
                  automargin: true,
                  tickfont: { size: 14 },
                },
              }}
              config={{ responsive: true }} 
            />
          </div>


        </div>
      )}

      {/* Error Modal */}
      <Modal
        title="Error"
        visible={isErrorModalVisible}
        onCancel={() => setIsErrorModalVisible(false)}
        footer={[
          <Button key="ok" onClick={() => setIsErrorModalVisible(false)} style={{ backgroundColor: "#d9534f", color: "white", borderRadius: "5px" }}>
            Cerrar
          </Button>,
        ]}
      >
        <p>{error}</p>
      </Modal>
    </div>
  );
}
