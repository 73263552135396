import React from "react";
import { Link } from "react-router-dom";

export default function BreadcrumbF() {
  const code = sessionStorage.getItem("code");
  return (
    <>
      <div className="col-12 col-md-6 mx-3 mt-3">
        {/* Breadcrumb para Group analysis */}
        <div>
          <Link
            className="btn btn-md rounded-pill link-style"
            to={`/home/?code=${code}`}
            style={{
              backgroundColor: "#050227",
              color: "white",
              borderRadius: "50px",
            }}
          >
            Inicio
          </Link>
          <span className="link-cout d-none d-sm-inline">{" > "}</span>
          <Link
            className="btn btn-md rounded-pill link-style d-none d-sm-inline"
            to={`/faqs/?code=${code}`}
            style={{
              backgroundColor: "#050227",
              color: "white",
              borderRadius: "50px",
            }}
          >
            FAQS
          </Link>
        </div>
      </div>
    </>
  );
}
