import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import fondo from "../images/FONDO.png";
import Loading from "../components/loading/Loading";

// Importación perezosa (lazy) de los componentes
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const BreadcrumbD = React.lazy(() =>
  import("../components/common/BreadcrumbD")
);
const FinantialNews = React.lazy(() =>
  import("../components/finantialNews/FinantialNews")
);

export default function DailyIndividualReportPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        overflowY: "hidden",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Suspense fallback={<Loading />}>
        <div
          className="page-container"
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            width: "100%",
            margin: 0,
            padding: 0,
            boxSizing: "border-box",
          }}
        >
          <Header />
          <BreadcrumbD />
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 1rem",
              maxWidth: "100%",
              boxSizing: "border-box",
            }}
          >
            <FinantialNews />
          </div>
          <Footer />
        </div>
      </Suspense>
    </div>
  );
}
