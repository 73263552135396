import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalculator } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { FaChartBar } from "react-icons/fa";

//Components
import CalculatorModal from "../common/modals/calculadoraPlazoEfectoModal";
import SpreadChartModal from "../common/modals/spreadAndReturnsModal";
import CustomTooltip from "../common/CustomTooltip";
import fondo from "../../images/FONDO.png";
import BlackScholesCalculator from "./modals/B&SModal";

//Images
import GlobxPinguino from "../../images/LOGOGX_pinguino.png";

//bootsrap
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button, Modal } from "react-bootstrap";
import { FaBars } from "react-icons/fa";

import Dropdown from "react-bootstrap/Dropdown";

// DATA FILES
import tooltips_text from "../../data/tooltips_text.json";

export default function Header() {
  const username = sessionStorage.getItem("username");
  const [isCalculatorOpen, setCalculatorOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSpreadChartOpen, setSpreadChartOpen] = useState(false);

  //Get code
  const code = sessionStorage.getItem("code");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  const Logout = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.BASE_URL}`;
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("code");
    sessionStorage.removeItem("codeExpire");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("id_token");
  };

  const toggleCalculator = () => {
    setCalculatorOpen(!isCalculatorOpen);
  };

  const toggleSpreadChart = () => {
    setSpreadChartOpen(!isSpreadChartOpen);
  };

  return (
    <>
      {/* ROW START*/}
      <div className="row m-0 align-items-center">
        {/* Logo Section 4 Columns*/}
        <div className="col-4 col-md-4 text-md-start my-2 my-md-0">
          <nav className="navbar navbar-light">
            <div className="d-flex align-items-center">
              <img
                src={GlobxPinguino}
                alt="logo"
                style={{ width: "70px", height: "70px", marginRight: "12px" }}
              />
              <h5
                className="text-md-start mt-2 mt-md-0"
                style={{
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontSize: "3rem",
                  margin: 0,
                }}
              >
                Globx
              </h5>
            </div>
          </nav>
        </div>

        {/* Items section 8 Columns*/}
        <div className="col-8 col-md-8 text-end">
          <span
            className="text-md-start mt-2 mt-md-0"
            style={{
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "2rem",
              margin: 0,
              marginRight: "2vh",
            }}
          >
            {username}
          </span>

          <FaBars
            size={24}
            color="white"
            style={{ cursor: "pointer", marginRight: "2vh" }}
            onClick={handleShow}
          />

          <CustomTooltip
            text={tooltips_text.calculadora_plazo_efecto}
            placement="left"
          >
            <span>
              <FaCalculator
                onClick={toggleCalculator}
                style={{
                  cursor: "pointer",
                  fontSize: "1.5rem",
                  color: "white",
                  marginRight: "2vh",
                }}
              />
            </span>
          </CustomTooltip>

          <CustomTooltip
            text={tooltips_text.Spread_y_Rendimiento}
            placement="left"
          >
            <span>
              <FaChartBar
                onClick={toggleSpreadChart}
                style={{
                  cursor: "pointer",
                  fontSize: "1.5rem",
                  color: "white",
                  marginRight: "2vh",
                }}
              />
            </span>
          </CustomTooltip>

          <CustomTooltip text={tooltips_text.faqs} placement="left">
            <span>
              <FaQuestionCircle
                onClick={() => navigate(`/faqs/?code=${code}`)}
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                  color: "white",
                  marginRight: "2vh",
                }}
              />
            </span>
          </CustomTooltip>

          <button
            type="button"
            className="btn btn-secondary btn-md"
            style={{ background: "none", color: "white", border: "none" }}
            onClick={Logout}
          >
            <FaSignOutAlt
              style={{
                cursor: "pointer",
                fontSize: "2rem",
                color: "white",
                marginRight: "2vh",
              }}
            />
          </button>
        </div>

        <Offcanvas
          show={show}
          onHide={handleClose}
          style={{ backgroundImage: `url(${fondo})` }}
        >
          <Offcanvas.Header>
            <Offcanvas.Title style={{ color: "white" }}>
              Herramientas
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="mb-3">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "#050227",
                    color: "white",
                    width: "100%",
                    maxWidth: "300px",
                    borderRadius: "50px",
                  }}
                >
                  Reporte diario
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      navigate(`/dailyIndividualReport/?code=${code}`)
                    }
                  >
                    Individual
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => navigate(`/dailyGroupReport/?code=${code}`)}
                  >
                    Grupal
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mb-3">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor: "#050227",
                    color: "white",
                    width: "100%",
                    maxWidth: "300px",
                    borderRadius: "50px",
                  }}
                >
                  Checklist
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      navigate(`/individualAnalysis/?code=${code}`)
                    }
                  >
                    Individual
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => navigate(`/groupAnalysis/?code=${code}`)}
                  >
                    Grupal
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mb-3">
              <Button
                style={{
                  backgroundColor: "#050227",
                  color: "white",
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "50px",
                }}
                onClick={() => navigate(`/correlationStudy/?code=${code}`)}
                ong
              >
                Estudio de correlación
              </Button>
            </div>
            <div className="mb-3">
              <Button
                style={{
                  backgroundColor: "#050227",
                  color: "white",
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "50px",
                }}
                onClick={() => navigate(`/finantialnews/?code=${code}`)}
                ong
              >
                Noticias financieras
              </Button>
            </div>
            <div className="mb-3">
              <Button
                style={{
                  backgroundColor: "#050227",
                  color: "white",
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "50px",
                }}
                onClick={() => navigate(`/positionRegister/?code=${code}`)}
                ong
              >
                Registro de posiciones
              </Button>
            </div>
            <div className="mb-3">
              <Button
                style={{
                  backgroundColor: "#050227",
                  color: "white",
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "50px",
                }}
                onClick={() => navigate(`/historicTable/?code=${code}`)}
                ong
              >
                Histórico
              </Button>
            </div>
            <div className="mb-3">
              <Button
                onClick={toggleCalculator}
                style={{
                  backgroundColor: "#050227",
                  color: "white",
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "50px",
                }}
              >
                Calculadora plazo efecto
              </Button>
            </div>
            <div className="mb-3">
              <Button
                onClick={() => navigate(`/faqs/?code=${code}`)}
                style={{
                  backgroundColor: "#050227",
                  color: "white",
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "50px",
                }}
              >
                Preguntas frecuentes
              </Button>
            </div>
            <div className="mb-3">
              <Button
                style={{
                  backgroundColor: "#050227",
                  color: "white",
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "50px",
                }}
                onClick={handleOpenModal}
              >
                Black Scholes Calculator
              </Button>
            </div>
          </Offcanvas.Body>
          <div
            className="offcanvas-footer"
            style={{
              padding: "10px",
              textAlign: "start",
              backgroundColor: "#050227",
              color: "white",
            }}
          >
            Desarrollado por Data Pulse Analytics.
          </div>
        </Offcanvas>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        size="lg"
        dialogClassName="custom-modal-dialog"
      >
        <div
          style={{
            backgroundImage: `url(${fondo})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Black Scholes Calculator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BlackScholesCalculator />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-md"
              style={{
                backgroundColor: "#050227",
                color: "white",
                width: "100%",
                maxWidth: "300px",
                borderRadius: "50px",
              }}
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      {isCalculatorOpen && <CalculatorModal onClose={toggleCalculator} />}

      {isSpreadChartOpen && <SpreadChartModal onClose={toggleSpreadChart} />}
    </>
  );
}
