import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import fondo from "../images/FONDO.png";
import Loading from "../components/loading/Loading";

// Importación perezosa (lazy) de los componentes
const Header = React.lazy(() => import("../components/common/Header"));
const Footer = React.lazy(() => import("../components/common/Footer"));
const BreadcrumbPR = React.lazy(() =>
  import("../components/common/BreadcrumbPR")
);
const PositionRegister = React.lazy(() =>
  import("../components/positionRegister/PositionRegister")
);

export default function PositionRegisterPage() {
  //Auth and Loading variables
  const isAuthenticated = sessionStorage.getItem("token");

  // If not authenticated, redirect to Login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        overflowY: "hidden",
        width: "100%",
      }}
    >
      <Suspense fallback={<Loading />}>
        <Header />
        <BreadcrumbPR />
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 1rem",
          }}
        >
          <PositionRegister />
        </div>
        <Footer />
      </Suspense>
    </div>
  );
}
